.c-form {
    margin-top: rem(30px);
    @include clearfix;

    input {
        height: 44px;
    }

    input[type="submit"] {
        // Medium Max Desktops
        @include media-max(md) {
            margin-top: rem(15px);
        }
    }
}
