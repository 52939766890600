///
// Projects
///
.c-press-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: neg(rem(15px));
    margin-right: neg(rem(15px));
}

///
// Press Items
///
.c-press-item {
    .c-press-items & {
        position: relative;
        margin-bottom: rem(30px);
        padding-left: rem(15px);
        padding-right: rem(15px);
        overflow: hidden;

        // Medium Desktops
        @include media(md) {
            width: 25%;
        }
    }
}

///
// Image
///
.c-press-item__image {
    margin-bottom: rem(10px);
}

///
// Link
///
.c-press-item__link {
    display: block;
}

///
// Gallery
///
.c-press-item__gallery {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li + li {
        margin-top: rem(30px);
    }

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

///
// Actions
///
.c-press-item__actions {
    margin-bottom: rem(15px);
    @include clearfix;

    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(15px);
    }
}

///
// Back
///
.c-press-item__back {
    font-size: rem(12px);
    font-family: $font-open-sans-regular;
    color: $base-font-color;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.35s;

    // Medium Desktops
    @include media(md) {
        float: left;
    }

    @include link-states() {
        color: color('green');
    }
}

///
// Title
///
.c-press-item__title {
    .c-press-item--single & {
        color: color('green');
    }
}

///
// Article
///
.c-press-item__article {
    text-transform: none;
}
