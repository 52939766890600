.c-header {
    // Medium Desktops
    @include media(md) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: z('header');
        width: 100%;
    }

    .logged-in & {
        // Medium Desktops
        @include media(md) {
            top: 32px;
        }
    }

    .is-scrolling & {
        // Medium Desktops
        @include media(md) {
            background-color: rgba(white, 0.75);
        }
    }
}
