input,
select,
textarea {
    width: 100%;
    padding: rem(10px);
    font-size: rem(20px);
    line-height: 1.3;
    border: 2px solid $base-font-color;
    border-radius: 0;
}

input[type="submit"] {
    width: auto;
    font-size: rem(20px);
    font-family: $font-cormorant-garamond-bold;
    color: white;
    text-transform: uppercase;
    background-color: color('green');
    border: 0;
    transition: background-color 0.35s;

    @include link-states() {
        background-color: $base-font-color;
    }
}

::-webkit-input-placeholder {
    font-size: rem(20px);
    color: $base-font-color;
    text-transform: uppercase;
}

::-moz-placeholder {
    font-size: rem(20px);
    color: $base-font-color;
    text-transform: uppercase;
}

:-ms-input-placeholder {
    font-size: rem(20px);
    color: $base-font-color;
    text-transform: uppercase;
}

:-moz-placeholder {
    font-size: rem(20px);
    color: $base-font-color;
    text-transform: uppercase;
}
