///
// Breakpoints
///
$breakpoints: (
    xs: 480px,  // Extra Small devices (landscape phones, 480px and up)
    sm: 768px,  // Small devices (tablets, 768px and up)
    md: 1024px, // Medium devices (desktops, 1024px and up)
    lg: 1200px, // Large devices (desktops, 1200px and up)
    xl: 1500px  // Extra Lage devices (desktops, 1500px and up)
);

///
// Base global defaults
///
$base-columns:      12;
$base-gutter:       30px;
$base-font-size:    16px;
$base-font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-line-height:  1.8;
$base-font-color:   #4d4f53;

///
// Font sizes
///
$font-sizes: (
    'h1': (
        'initial':  22px,
        'sm':       26px
    ),
    'h2': (
        'initial':  20px,
        'sm':       32px
    ),
    'h3': (
        'initial':  18px,
        'sm':       18px
    ),
    'h4': (
        'initial':  16px,
        'sm':       16px
    ),
    'h5':           16px,
    'h6':           12px
);

///
// Enable flexbox grid
///
$flexbox: false;

///
// Column offsets
//
// Will enable the creation of column offset classes
///
$column-offsets: false;

///
// Debug mode
//
// In order to use the debug mode you need to set the below variable to true
// and you also need to set the THEME_DEBUG constant in the wp-config to true
///
$debug: false;

///
// Colors Map for @function color();
///
$colors: (
    'grey': (
        'default':      #efefef,
        'medium':       #e5e5e5,
        'lighest':      #f7f7f4,
        'dark':         #65646a
    ),
    'bigrigmedia': (
        'orange':       #f6921e,
        'grey':         #4d4d4d
    ),
    'green': (
        'default':      #7b8256
    )
);

///
// Alerts Map for @function alert();
///
$alerts: (
    'success': (
        'default':  #dff0d8,
        'message':  #3c763d,
        'border':   #d6e9c6
    ),
    'info': (
        'default':  #d9edf7,
        'message':  #31708f,
        'border':   #bce8f1
    ),
    'warning': (
        'default':  #fcf8e3,
        'message':  #8a6d3b,
        'border':   #faf2cc
    ),
    'error': (
        'default':  #f2dede,
        'message':  #a94442,
        'border':   #ebcccc
    )
);

///
// Social Networks Map for @function social();
///
$social-networks: (
    facebook:   #3b5998,
    twitter:    #00aced,
    googleplus: #dd4b39,
    linkedin:   #007bb6,
    youtube:    #bb0000,
    instagram:  #517fa4,
    pinterest:  #cb2027,
    flickr:     #ff0084,
    tumblr:     #32506d,
    foursquare: #0072b1,
    dribble:    #ea4c89,
    vine:       #00bf8f
);

///
// Z-index Map for @function z();
///
$z-index: (
    debug: 1,
    header: 2000,
    mobile-nav: 1100,
    nav-toggle: 3000
);

///
// Fonts
///
$font-cormorant-garamond-regular: 'cormorant_garamondregular';
$font-cormorant-garamond-italic: 'cormorant_garamonditalic';
$font-cormorant-garamond-bold: 'cormorant_garamondbold';
$font-cormorant-garamond-medium: 'cormorant_garamondmedium';
$font-open-sans-regular: 'open_sansregular';
$font-open-sans-semi-bold: 'open_sanssemibold';
