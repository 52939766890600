///
// Custom fonts
///

// Example
// @font-face {
//  font-family: 'questrialregular';
//  src: url('../../dist/fonts/questrial-regular-webfont.woff2') format('woff2'),
//       url('../../dist/fonts/questrial-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
// }

@font-face {
    font-family: 'cormorant_garamondbold';
    src: url('../../dist/fonts/cormorantgaramond-bold-webfont.woff2') format('woff2'),
         url('../../dist/fonts/cormorantgaramond-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cormorant_garamondbold_italic';
    src: url('../../dist/fonts/cormorantgaramond-bolditalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/cormorantgaramond-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cormorant_garamonditalic';
    src: url('../../dist/fonts/cormorantgaramond-italic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/cormorantgaramond-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cormorant_garamondlight';
    src: url('../../dist/fonts/cormorantgaramond-light-webfont.woff2') format('woff2'),
         url('../../dist/fonts/cormorantgaramond-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cormorant_garamondLtIt';
    src: url('../../dist/fonts/cormorantgaramond-lightitalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/cormorantgaramond-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cormorant_garamondmedium';
    src: url('../../dist/fonts/cormorantgaramond-medium-webfont.woff2') format('woff2'),
         url('../../dist/fonts/cormorantgaramond-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cormorant_garamondMdIt';
    src: url('../../dist/fonts/cormorantgaramond-mediumitalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/cormorantgaramond-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cormorant_garamondregular';
    src: url('../../dist/fonts/cormorantgaramond-regular-webfont.woff2') format('woff2'),
         url('../../dist/fonts/cormorantgaramond-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cormorant_garamondsemibold';
    src: url('../../dist/fonts/cormorantgaramond-semibold-webfont.woff2') format('woff2'),
         url('../../dist/fonts/cormorantgaramond-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cormorant_garamondSBdIt';
    src: url('../../dist/fonts/cormorantgaramond-semibolditalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/cormorantgaramond-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansbold';
    src: url('../../dist/fonts/opensans-bold-webfont.woff2') format('woff2'),
         url('../../dist/fonts/opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansbold_italic';
    src: url('../../dist/fonts/opensans-bolditalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/opensans-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansextrabold';
    src: url('../../dist/fonts/opensans-extrabold-webfont.woff2') format('woff2'),
         url('../../dist/fonts/opensans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansextrabold_italic';
    src: url('../../dist/fonts/opensans-extrabolditalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/opensans-extrabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansitalic';
    src: url('../../dist/fonts/opensans-italic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/opensans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanslight';
    src: url('../../dist/fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../../dist/fonts/opensans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanslight_italic';
    src: url('../../dist/fonts/opensans-lightitalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/opensans-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansregular';
    src: url('../../dist/fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../../dist/fonts/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanssemibold';
    src: url('../../dist/fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../../dist/fonts/opensans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanssemibold_italic';
    src: url('../../dist/fonts/opensans-semibolditalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/opensans-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
