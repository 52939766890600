.c-section {
    .c-info {
        span {
            display: block;
            font-size: rem(18px);
            color: color('green');
            text-transform: uppercase;
        }
    }

    .c-info--location {
        span + span {
            font-size: rem(20px);
            color: $base-font-color;
        }
    }

    .c-info--email {
        font-family: $font-cormorant-garamond-bold;
    }

    .c-info--newsletter {
        margin-bottom: rem(10px);
    }
}

///
// Main
///
.c-section--main {
    .page-id-13 & {
        padding-top: rem(30px);
    }
}

///
// Section Wrap
///
.c-section__wrap {
    padding: rem(15px) rem(30px);

    // Medium Desktops
    @include media(md) {
        padding: rem(30px) rem(90px);
    }

    .page-template-template-profile & {
        margin-top: rem(30px);
    }
}

///
// Grey Section Wrap
///
.c-section__wrap--grey {
    background-color: color('grey', 'lighest');
}

///
// Social
///
.c-section__social {
    p {
        margin-bottom: 0;
        font-family: $font-cormorant-garamond-medium;
        text-transform: uppercase;
    }

    span {
        color: color('green');
    }

    .fa {
        color: $base-font-color;
    }
}
