.c-share {
    font-size: rem(12px);
    font-family: $font-open-sans-regular;
    text-transform: uppercase;

    .c-project__actions & {
        float: right;
    }

    a {
        text-decoration: none;
    }
}

///
// Social
///
.c-share__social {
    display: none;

    .page-template-template-contact &,
    .page-template-template-social & {
        display: block;
    }

    .single-projects & {
        margin-top: rem(10px);
    }

    span {
        position: relative;
        top: neg(2px);
        margin-right: rem(20px);
        font-size: rem(12px);
        font-family: $font-open-sans-regular;
    }

    a {
        color: $base-font-color;

        .page-template-template-contact &,
        .page-template-template-social & {
            margin-right: rem(10px);
            font-size: rem(21px);
        }

        @include link-states() {
            color: color('green');
        }
    }
}

///
// Icon
///
.c-share__icon {
    &:before {
        content: '';
        font-size: rem(21px);
        font-family: 'FontAwesome';
    }
}

.c-share__icon + .c-share__icon {
    margin-left: rem(5px);
}

///
// Instagram
///
.c-share__icon--instagram:before {
    content: '\f16d';
}

///
// Pinterest
///
.c-share__icon--pinterest:before {
    content: '\f231';
}

///
// Facebook
///
.c-share__icon--facebook:before {
    content: '\f230';
}

///
// Twitter
///
.c-share__icon--twitter:before {
    content: '\f099';
}

///
// Email
///
.c-share__icon--email:before {
    content: '\f0e0';
}
