///
// None, Left & Right
///
.alignnone,
.alignleft,
.alignright {
    // Medium Max Desktops
    @include media-max(md) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

///
// Left & Right
///
.alignleft,
.alignright {
    margin-bottom: rem(20px);
}

///
// Center
///
.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

///
// Left
///
.alignleft {
    // Medium Desktops
    @include media(md) {
        float: left;
        margin-right: rem(20px);
    }
}

///
// Right
///
.alignright {
    // Medium Desktops
    @include media(md) {
        float: right;
        margin-left: rem(20px);
    }
}

///
// Gallery
///
.gallery-columns-2,
.gallery-columns-3,
.gallery-columns-4,
.gallery-columns-5 {
    @include make-row;
}

///
// Gallery Item
///
.gallery-item {
    position: relative;
    width: 100%;
    min-height: 1px;
    margin-bottom: rem(30px);
    padding-right: rem(15px);
    padding-left: rem(15px);

    // Medium Desktops
    @include media(md) {
        float: left;
    }

    .gallery-columns-2 & {
        // Medium Desktops
        @include media(md) {
            width: 50%;
        }
    }

    .gallery-columns-3 & {
        // Medium Desktops
        @include media(md) {
            width: 33.3333333333%;
        }
    }

    .gallery-columns-4 & {
        // Medium Desktops
        @include media(md) {
            width: 25%;
        }
    }

    .gallery-columns-5 & {
        // Medium Desktops
        @include media(md) {
            width: 20%;
        }
    }
}
