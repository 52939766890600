h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
}

h1,
h3,
h4,
h5 {
    font-family: $font-cormorant-garamond-bold;
    text-transform: uppercase;
}

h1 {
    @include font-size('h1');
}

h2 {
    @include font-size('h2');
    color: color('green');
}

h3 {
    @include font-size('h3');
    color: color('grey', 'dark');
}

h4 {
    @include font-size('h4');
}

h5 {
    @include font-size('h5');
}

h6 {
    @include font-size('h6');
}

p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem(18px);
}

p:not(:last-child) {
    margin-bottom: rem(30px);
}

em {
    font-family: $font-cormorant-garamond-italic;
    font-style: normal;
    color: color('green');
}

a {
    color: color('green');
    text-decoration: none;
}

strong {
    font-family: $font-cormorant-garamond-bold;
    font-weight: 500;
    color: $base-font-color;
}

blockquote {
    margin: 0 0 rem(30px);
    font-size: rem(20px);
    font-family: $font-cormorant-garamond-italic;
    color: color('green');

    p {
        font-size: inherit;
    }
}

small {
    font-size: rem(12px);
    font-family: $font-open-sans-semi-bold;
}
