///
// Submit
///
.c-form__submit {
    width: 100% !important;
}

///
// Instagram Feed
///
#sbi_images {
    float: none !important;
    width: auto !important;
    margin-left: neg(rem(15px)) !important;
    margin-right: neg(rem(15px)) !important;
    padding: 0 !important;

    // Medium Desktops
    @include media(md) {
        width: 100% !important;
    }
}

#sb_instagram #sbi_images .sbi_item {
    padding: rem(15px) !important;
}

///
// Pinterest Feed
///
.pins-feed-list {
    width: auto !important;
    margin-left: neg(rem(15px)) !important;
    margin-right: neg(rem(15px)) !important;

    // Medium Desktops
    @include media(md) {
        width: 100% !important;
    }
}

.pins-feed-item {
    width: 100% !important;
    margin: 0 !important;
    padding: rem(15px) !important;

    // Medium Desktops
    @include media(md) {
        width: 25% !important;
    }
}

.nailthumb-container {
    width: 100% !important;
    height: 100% !important;
}

#pins-feed-follow {
    display: none;
}
