///
// Slick Slider
///
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

///
// Slick List
///
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

///
// Slick Track & List
///
.slick-track,
.slick-list {
    transform: translate3d(0, 0, 0);

    .c-hero & {
        height: 100%;
    }
}

///
// Slick Track
///
.slick-track {
    display: block;
    position: relative;
    left: 0;
    top: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

///
// Slick Slide
///
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

///
// Slick Hidden
///
.slick-hidden {
    display: none;
}

///
// Dots
///
.slick-dots {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    text-align: center;

    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(30px);
    }

    li {
        display: inline-block;
        vertical-align: top;
    }

    button {
        width: 15px;
        height: 15px;
        margin-left: rem(3px);
        margin-right: rem(3px);
        padding: 0;
        color: transparent;
        background-color: color('grey');
        border: 0;
        border-radius: 100%;
        appearance: none;
        outline: none;
    }
}

///
// Active Dot
///
.slick-active {

}
