///
// Collections
///
.c-collections {
    display: flex;
    flex-wrap: wrap;
}

///
// Collection
///
.c-collection {
    width: 100%;

    // Medium Desktops
    @include media(md) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.c-collection + .c-collection {
    margin-top: rem(30px);
    padding-top: rem(30px);
    border-top: 1px solid $base-font-color;
}

///
// Image & Body
///
.c-collection__image,
.c-collection__body {
    width: 100%;
    padding-left: rem(15px);
    padding-right: rem(15px);

    // Medium Desktops
    @include media(md) {
        width: 50%;

        &.full {
          width: 100%;
        }
    }
}

///
// Body
///
.c-collection__body {
    h1 {
        color: color('green');
    }

    h2 {
        margin-bottom: rem(20px);
        color: $base-font-color;
        text-transform: none;
    }
}
