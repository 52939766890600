///
// Navigation
///
.c-toggle-nav {
    display: inline-block;
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: z('nav-toggle');
    width: 32px;
    height: 32px;

    // Medium Desktops
    @include media(md) {
        display: none;
    }

    &.is-open {
        .c-toggle-icon {
            background-color: transparent;

            &:after,
            &:before {
                top: 0;
                background-color: color('green');
            }

            &:after { transform: rotate(-45deg); }

            &:before { transform: rotate(45deg); }
        }
    }
}

///
// Dropdown
///
.c-toggle-dropdown {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    align-items: flex-end;
    justify-content: flex-start;

    // Medium Max Desktops
    @include media-max(md) {
        width: 32px;
        height: 32px;
    }

    // Medium Desktops
    @include media(md) {
        display: none;
        top: 5px;
        right: neg(30px);
        width: 15px;
        height: 15px;
    }

    &:after {
        content: '\f107';
        font-size: rem(24px);
        font-family: 'FontAwesome';
        color: white;

        .is-expanded & {
            content: '\f106';
        }
    }
}

///
// Toggle Icon
///
.c-toggle-icon {
    display: inline-block;
    position: absolute;
    top: 30%;
    right: 0;
    width: 32px;
    height: 2px;
    margin-left: auto;
    margin-right: auto;
    background-color: color('green');
    border-radius: 5px;
    transition: transform 0.3s;

    &:after,
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 32px;
        height: 2px;
        margin-left: auto;
        margin-right: auto;
        background-color: inherit;
        border-radius: 5px;
        transition: transform 0.3s;
    }

    &:after {
        top: 9px;
    }

    &:before {
        bottom: 9px;
    }
}
