.o-container {
    @include make-container;
    @include clearfix;
}

///
// Fluid Container
///
.o-container-fluid {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));
}
