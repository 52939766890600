.c-btn {
    display: inline-block;
    padding: rem(5px) rem(10px);
    text-decoration: none;
}

///
// Green
///
.c-btn--green {
    background-color: color('green');

    @include link-states() {
        background-color: $base-font-color;
    }
}

///
// Scroll
///
.c-btn--scroll {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 42px;
    height: 42px;
    opacity: 0;
    transition: opacity 0.45s;

    &:before {
        content: '\f106';
        font-size: rem(24px);
        font-family: 'FontAwesome';
        color: white;
    }

    &.is-active {
        opacity: 1;
    }
}
