body {
    position: relative;
    margin: 0;
    font-size: $base-font-size;
    font-family: $font-cormorant-garamond-regular;
    line-height: $base-line-height;
    letter-spacing: 1px;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(240px);
    }
}

hr {
    height: 1px;
    margin-top: rem(30px);
    margin-bottom: rem(30px);
    background-color: $base-font-color;
    border: 0;
}
