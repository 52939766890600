///
// Footer
///
.c-footer {
    padding-top: rem(15px);
    padding-bottom: rem(15px);
}

///
// Copyright
///
.c-footer__copyright {
    margin-top: rem(30px);
    font-size: rem(14px);
    font-family: $font-cormorant-garamond-bold;
    color: $base-font-color;
    text-transform: uppercase;

    a {
        color: inherit;
    }

    span {
        font-size: rem(12px);
    }
}
