.c-logo {
    display: block;
    width: 100%;
    max-width: 215px;
    margin: rem(10px) auto rem(30px);

    // Medium Desktops
    @include media(md) {
        transition: all 0.35s;
    }

    .is-scrolling & {
        // Medium Desktops
        @include media(md) {
            max-width: 143px;
            margin-bottom: rem(15px);
        }
    }
}
