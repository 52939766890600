///
// Nav
///
.c-nav {
    // Medium Max Desktops
    @include media-max(md) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: z('mobile-nav');
        width: 100%;
        height: 100%;
        padding: rem(60px) rem(30px);
        background-color: rgba(white, 0.85);
        visibility: hidden;
        opacity: 0;
        transition: all 0.35s;
        overflow-y: scroll;
    }

    // Medium Desktops
    @include media(md) {
        margin-bottom: rem(30px);
    }

    .is-scrolling & {
        // Medium Desktops
        @include media(md) {
            margin-bottom: rem(15px);
        }
    }

    &.is-open {
        opacity: 1;
        visibility: visible;
    }
}

///
// Nav List
///
.c-nav__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    text-align: center;
}

///
// Nav Item
///
.c-nav__item {
    // Medium Max Desktops
    @include media-max(md) {
        margin-bottom: rem(20px);
    }

    // Medium Desktops
    @include media(md) {
        display: inline-block;
        vertical-align: top;
    }

    &.is-active {
        a {
            color: color('green');
        }
    }

    a {
        font-size: rem(24px);
        font-family: $font-cormorant-garamond-bold;
        color: $base-font-color;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.35s;

        // Medium Desktops
        @include media(md) {
            font-size: rem(16px);
        }

        @include link-states() {
            color: color('green');
        }
    }
}

.c-nav__item + .c-nav__item {
    // Medium Desktops
    @include media(md) {
        margin-left: rem(30px);
    }
}
