.c-alert {
    margin-bottom: rem(30px);
    padding: rem(halve($base-gutter));
    border: 1px solid transparent;
}

///
// Success
///
.c-alert--success {
    color: alert('success', 'message');
    background-color: alert('success');
    border-color: alert('success', 'border');
}

///
// Info
///
.c-alert--info {
    color: alert('info', 'message');
    background-color: alert('info');
    border-color: alert('info', 'border');
}

///
// Warning
///
.c-alert--warning {
    color: alert('warning', 'message');
    background-color: alert('warning');
    border-color: alert('warning', 'border');
}

///
// Error
///
.c-alert--error {
    color: alert('error', 'message');
    background-color: alert('error');
    border-color: alert('error', 'border');
}
