///
// Generic hidden visibility
///
.u-hidden {
    display: none;
}

///
// Create breakpoint based hidden visibility
///
@each $breakpoint in map-keys($breakpoints) {
    @include media($breakpoint) {
        .u-hidden\@#{$breakpoint} {
            display: none;
        }
    }
}
