///
// Projects
///
.c-projects {
    display: flex;
    flex-wrap: wrap;
    margin-left: neg(rem(15px));
    margin-right: neg(rem(15px));
}

///
// Project
///
.c-project {
    .c-projects & {
        position: relative;
        margin-bottom: rem(30px);
        padding-left: rem(15px);
        padding-right: rem(15px);
        overflow: hidden;

        // Medium Desktops
        @include media(md) {
            width: 50%;
        }
    }
}

///
// Link
///
.c-project__link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
    width: auto;
    height: auto;
    text-decoration: none;

    // Medium Desktops
    @include media(md) {
        transition: background-color 0.75s;
    }

    .c-project:hover & {
        // Medium Desktops
        @include media(md) {
            background-color: rgba(white, 0.75);
        }
    }
}

///
// Inner
///
.c-project__inner {
    width: 100%;
}

///
// Title & Body
///
.c-project__title,
.c-project__body {
    .c-projects & {
        display: block;
        text-transform: uppercase;
        opacity: 0;
        transition: opacity 0.35s, transform 0.50s;
    }

    .c-project:not(.c-project--single):hover & {
        // Medium Desktops
        @include media(md) {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

///
// Title
///
.c-project__title {
    color: color('green');

    .c-projects & {
        // Medium Desktops
        @include media(md) {
            transform: translateX(30%);
        }
    }

    .c-project--single & {
        margin-bottom: 0;
    }
}

///
// Body
///
.c-project__body {
    .c-projects & {
        // Medium Desktops
        @include media(md) {
            transform: translateX(-30%);
        }
    }
}

///
// Images
///
.c-project__images {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

///
// Image
///
.c-project__image + .c-project__image {
    .c-project--single & {
        margin-top: rem(30px);
    }
}

///
// Actions
///
.c-project__actions {
    margin-bottom: rem(15px);
    @include clearfix;

    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(15px);
    }
}

///
// Back
///
.c-project__back {
    font-size: rem(12px);
    font-family: $font-open-sans-regular;
    color: $base-font-color;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.35s;

    // Medium Desktops
    @include media(md) {
        float: left;
    }

    @include link-states() {
        color: color('green');
    }
}
